import Link from 'next/link';

import { RightArrowIcon } from '@/app/components/icons/RightArrowIcon';
import { SheerCasePreview } from '@/app/components/sheercase/SheerCasePreview';
import { Event } from '@/types/sheercases';
import {
  casesCompletedInLast72Hours,
  defaultValidCases,
  mayNeedActionEvents,
  needsActionEvents,
  sheerRepliedInLast72Hrs,
} from '@/utils/sheerCaseUtils';

export const CasePreviewList = ({
  events,
  memberFirstName,
}: {
  events: Event[] | null;
  memberFirstName: string;
}) => {
  const orderedEvents = () => {
    const filteredEvents = defaultValidCases(events);
    const needsAction = needsActionEvents(filteredEvents);
    const mayNeedAction = mayNeedActionEvents(filteredEvents);
    const completedInLast72Hrs = casesCompletedInLast72Hours(filteredEvents);
    const sheerRepliedLast72Hrs = sheerRepliedInLast72Hrs(filteredEvents);

    return [
      ...needsAction,
      ...mayNeedAction,
      ...completedInLast72Hrs,
      ...sheerRepliedLast72Hrs,
    ];
  };

  const caption = () => {
    const len = orderedEvents().length;
    if (len === 0) return 'No recent updates';
    if (len === 1) return '1 update';
    return `${len} updates`;
  };

  return (
    <div className='flex flex-col w-full gap-4 p-2 lg:w-full'>
      <p className='text-[18px] text-[#65636D]'>{caption()}</p>
      {orderedEvents().map(event => (
        <SheerCasePreview
          key={event.id}
          event={event}
          memberName={memberFirstName}
        />
      ))}
      <Link
        href={'/cases'}
        className='flex flex-row items-center justify-between gap-2 hover:bg-[#FAF9FB] transition-all px-5 py-5 bg-white rounded-[8px] border-[.5px] border-solid border-[#ECEAEF] shadow-6'
      >
        <p className='text-[16px] text-[#211F26]'>View all cases</p>
        <RightArrowIcon />
      </Link>
    </div>
  );
};
