import { useRouter } from 'next/navigation';

import { CaseStatus } from '@/consts/sheerCaseConsts';
import { Event } from '@/types/sheercases';
import {
  convertTimestampToDateFormat,
  convertTimestampToDayFormat,
} from '@/utils/dateUtils';

import { CaretIcon } from '../icons/CaretIcon';

const { COMPLETE, DISMISSED, IN_PROGRESS, MAY_NEED_ACTION, NEEDS_ACTION } =
  CaseStatus;
const statusColor = {
  [IN_PROGRESS]: 'text-[#B890F5]',
  [COMPLETE]: 'text-[#0E9888]',
  [MAY_NEED_ACTION]: 'text-[#D6A35C]',
  [NEEDS_ACTION]: 'text-[#DA3A5C]',
  [DISMISSED]: 'text-[#817F8B]',
  default: 'text-[#B890F5]',
};
const statusMessage = {
  [IN_PROGRESS]: 'In Progress',
  [COMPLETE]: 'Complete',
  [MAY_NEED_ACTION]: 'For Your Review',
  [NEEDS_ACTION]: 'Needs Your Attention',
  [DISMISSED]: 'Dismissed',
  default: 'In Progress',
};

export const SheerCasePreview = ({
  event,
  memberName,
}: {
  event: Event;
  memberName: string;
}) => {
  const router = useRouter();
  const { amountSaved, messages, status, title } = event;
  if (!messages) return null;

  const lastMessage = messages[0];
  const { creatorFirstName, updatedAt } = lastMessage;
  const senderName =
    creatorFirstName === memberName ? 'You' : creatorFirstName || 'Sheer';
  const statusDisplay = amountSaved
    ? `$${amountSaved} saved 💸`
    : statusMessage[status];

  const onClick = () => router.push(`/cases/${event.id}`);

  const getDisplayDate = () => {
    const today = new Date();
    const oneWeekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    const isPastWeekOld = updatedAt.toDate() < oneWeekAgo;
    return isPastWeekOld
      ? convertTimestampToDateFormat(updatedAt)
      : convertTimestampToDayFormat(updatedAt);
  };

  return (
    <button
      className='bg-white rounded-[8px] border-[0.5px] border-solid border-[#ECEAEF] shadow-6 hover:bg-[#FAF9FB] transition-all'
      onClick={onClick}
    >
      <div className='flex items-center justify-between w-full gap-4 p-4'>
        <div className='flex flex-col w-5/6 gap-1'>
          <p className='text-[16px] text-left text-[#1C2024] text-nowrap text-ellipsis overflow-hidden'>
            {title}
          </p>
          <div className='flex flex-row gap-1 text-[14px] flex-nowrap'>
            <p className='text-[#817F8B] text-start text-nowrap'>
              {senderName}
            </p>
            <p className='text-[#817F8B]'> · </p>
            <p className='text-[#817F8B] text-start text-nowrap'>
              {getDisplayDate()}
            </p>
            <p className='text-[#817F8B]'> · </p>
            <p
              className={
                statusColor[status] +
                ' text-start text-nowrap text-ellipsis overflow-hidden'
              }
            >
              {statusDisplay}
            </p>
          </div>
        </div>
        <CaretIcon />
      </div>
    </button>
  );
};
