import { getRemoteConfig, RemoteConfig } from 'firebase/remote-config';

import { FirebaseApp } from './init';

let remoteConfigVar: RemoteConfig | undefined;

if (typeof window !== 'undefined') {
  remoteConfigVar = getRemoteConfig(FirebaseApp);
  remoteConfigVar.settings.minimumFetchIntervalMillis = 600000;
}

export const remoteConfig = remoteConfigVar;
