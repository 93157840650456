import getCurrentEnv from '@/utils/getCurrentEnv';

export type Portal =
  | 'aetna'
  | 'anthem'
  | 'cigna'
  | 'empire'
  | 'medicare'
  | 'umr'
  | 'united'
  | 'florida-bcbs'
  | 'ma-bcbs'
  | 'il-bcbs'
  | 'ok-bcbs'
  | 'tx-bcbs'
  | 'horizon'
  | 'meritain'
  | 'progyny'
  | 'metlife'
  | 'guardian'
  | 'nyu-langone'
  | 'ny-presbyterian'
  | 'express-scripts'
  | 'cvs-caremark'
  | 'delta-dental'
  | 'mt-sinai'
  | 'mock-portal';

export interface PortalDetails {
  displayName: string;
  serverName: string;
  logo: string;
  aliases?: string;
  hideOption?: boolean;
}

export const medicalPortalMap = {
  aetna: {
    displayName: 'Aetna',
    serverName: 'Aetna',
    logo: '/payerLogos/AetnaLogo.png',
  },
  anthem: {
    displayName: 'Anthem',
    serverName: 'Anthem',
    logo: '/payerLogos/AnthemLogo.png',
  },
  cigna: {
    displayName: 'Cigna',
    serverName: 'Cigna',
    logo: '/payerLogos/CignaLogo.png',
  },
  empire: {
    displayName: 'Empire Blue Cross and Blue Shield',
    serverName: 'New York Empire Blue Cross and Blue Shield',
    logo: '/payerLogos/EmpireLogo.png',
    aliases: 'bcbs, anthem',
  },
  medicare: {
    displayName: 'Medicare',
    serverName: 'Medicare',
    logo: '/payerLogos/MedicareLogo.png',
  },
  umr: {
    displayName: 'UMR',
    serverName: 'United Medical Resources',
    logo: '/payerLogos/UMRLogo.png',
  },
  united: {
    displayName: 'UnitedHealthcare',
    serverName: 'UnitedHealthcare',
    logo: '/payerLogos/UnitedHealthcareLogo.png',
    aliases: 'uhc, united healthcare',
  },
  meritain: {
    displayName: 'Meritain',
    serverName: 'Meritain',
    logo: '/payerLogos/MeritainHealthLogo.png',
    aliases: 'meritain health, meritain, aetna',
  },
  'florida-bcbs': {
    displayName: 'Florida Blue',
    serverName: 'Florida Blue Cross Blue Shield',
    logo: '/payerLogos/FloridaBCBSLogo.png',
    aliases: 'fl blue, bcbs florida, bcbs of florida',
  },
  'ma-bcbs': {
    displayName: 'Blue Cross Blue Shield of Massachusetts',
    serverName: 'Blue Cross Blue Shield of Massachusetts',
    logo: '/payerLogos/BCBS-MALogo.png',
    aliases: 'bcbs massachusetts, bcbs of massachusetts',
  },
  'il-bcbs': {
    displayName: 'Illinois Blue Cross Blue Shield',
    serverName: 'Illinois Blue Cross Blue Shield',
    logo: '/payerLogos/BCBS-ILLogo.png',
    aliases: 'bcbs illinois, bcbs of illinois',
  },
  'ok-bcbs': {
    displayName: 'Oklahoma Blue Cross Blue Shield',
    serverName: 'Oklahoma Blue Cross Blue Shield',
    logo: '/payerLogos/BCBS-OKLogo.png',
    aliases: 'bcbs oklahoma, bcbs of oklahoma',
  },
  'tx-bcbs': {
    displayName: 'Texas Blue Cross Blue Shield',
    serverName: 'Texas Blue Cross Blue Shield',
    logo: '/payerLogos/BCBS-TXLogo.png',
    aliases: 'bcbs texas, bcbs of texas',
  },
  horizon: {
    displayName: 'Horizon Blue Cross Blue Shield',
    serverName: 'Horizon Blue Cross Blue Shield',
    logo: '/payerLogos/HorizonBCBSLogo.png',
    aliases: 'bcbs horizon, horizon bcbs',
  },
  progyny: {
    displayName: 'Progyny',
    serverName: 'Progyny',
    logo: '/payerLogos/ProgynyLogo.png',
  },
};

const getInternalTestingPortalMap = () => {
  if (['development', 'scratch', 'staging'].includes(getCurrentEnv() || '')) {
    return {
      'mock-portal': {
        displayName: 'Mock Portal',
        serverName: 'Mock Portal',
        logo: '/payerLogos/MockPortalLogo.png',
      },
    };
  }
  return {};
};

export const dentalPortalMap = {
  aetna: {
    displayName: 'Aetna',
    serverName: 'Aetna',
    logo: '/payerLogos/AetnaLogo.png',
  },
  cigna: {
    displayName: 'Cigna',
    serverName: 'Cigna',
    logo: '/payerLogos/CignaLogo.png',
  },
  'delta-dental': {
    displayName: 'Delta Dental',
    serverName: 'Delta Dental',
    logo: '/payerLogos/DeltaDentalLogo.png',
  },
  guardian: {
    displayName: 'Guardian Dental',
    serverName: 'Guardian Dental',
    logo: '/payerLogos/GuardianDentalLogo.png',
  },
  metlife: {
    displayName: 'MetLife Dental',
    serverName: 'MetLife Dental Family',
    logo: '/payerLogos/MetLifeDentalLogo.png',
  },
  united: {
    displayName: 'UnitedHealthcare',
    serverName: 'UnitedHealthcare',
    logo: '/payerLogos/UnitedHealthcareLogo.png',
    aliases: 'uhc, united healthcare',
  },
};

export const myChartPortalMap = {
  'mt-sinai': {
    displayName: 'Mount Sinai MyChart',
    serverName: 'Mt. Sinai',
    logo: '/payerLogos/MtSinaiLogo.png',
    aliases: 'mt sinai',
  },
  'nyu-langone': {
    displayName: 'NYU Langone MyChart',
    serverName: 'NYU Langone Medical Center',
    logo: '/payerLogos/NYULangoneLogo.png',
  },
  'ny-presbyterian': {
    displayName: 'New York Presbyterian Hospital MyChart',
    serverName: 'New York Presbyterian',
    logo: '/payerLogos/NewYorkPresLogo.png',
  },
};

export const prescriptionsPortalMap = {
  'cvs-caremark': {
    displayName: 'CVS Caremark',
    serverName: 'CVS Caremark',
    logo: '/payerLogos/CVSCaremarkLogo.png',
  },
  'express-scripts': {
    displayName: 'Express Scripts',
    serverName: 'Express Scripts',
    logo: '/payerLogos/ExpressScriptsLogo.png',
  },
};

export const portalMap: Record<Portal, PortalDetails> = Object.freeze({
  ...(getInternalTestingPortalMap() as Record<Portal, PortalDetails>),
  ...medicalPortalMap,
  ...dentalPortalMap,
  ...myChartPortalMap,
  ...prescriptionsPortalMap,
});
