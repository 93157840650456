import { CaseStatus } from '@/consts/sheerCaseConsts';
import { Event } from '@/types/sheercases';

import { TIME_72_HOURS } from './dateUtils';

const { COMPLETE, DISMISSED, IN_PROGRESS, MAY_NEED_ACTION, NEEDS_ACTION } =
  CaseStatus;

// Single event filters

const baseFilter = (event: Event) =>
  event.messages.length && !isValidPlanAnalysis(event);

const searchFilter = (event: Event, filter: string) =>
  baseFilter(event) && event.title.toLowerCase().includes(filter.toLowerCase());

export const isValidPlanAnalysis = (event: Event) =>
  event.type === 'Medical Plan Selection' && event.messages.length;

export const caseIsFromLast72Hours = (event: Event) =>
  event.updatedAt.toDate() > new Date(new Date().getTime() - TIME_72_HOURS);

export const caseIsFromOver72HoursAgo = (event: Event) =>
  event.updatedAt.toDate() < new Date(new Date().getTime() - TIME_72_HOURS);

export const caseIsFromSheer = (event: Event) =>
  !event.messages[0].creatorFirstName;

export const caseIsFromMember = (event: Event) =>
  !!event.messages[0].creatorFirstName;

export const caseIsFromSheerOver72HrsAgo = (event: Event) =>
  caseIsFromSheer(event) && caseIsFromOver72HoursAgo(event);

// Event lists by Status

export const defaultValidCases = (events: Event[] | null, filter?: string) => {
  if (!events?.length) return [];
  return filter
    ? events.filter(event => searchFilter(event, filter))
    : events.filter(event => baseFilter(event));
};

export const inProgressEvents = (events: Event[]) =>
  events.filter(event => event.status === IN_PROGRESS);

export const mayNeedActionEvents = (events: Event[]) =>
  events.filter(event => event.status === MAY_NEED_ACTION);

export const needsActionEvents = (events: Event[]) =>
  events.filter(event => event.status === NEEDS_ACTION);

export const casesCompletedInLast72Hours = (events: Event[]) =>
  events.filter(
    event => caseIsFromLast72Hours(event) && event.status === COMPLETE,
  );

export const closedEvents = (events: Event[]) =>
  events.filter(
    event => event.status === NEEDS_ACTION || event.status === DISMISSED,
  );

// Event lists by Sender

export const sheerRepliedInLast72Hrs = (events: Event[]) =>
  events.filter(
    event =>
      caseIsFromLast72Hours(event) &&
      caseIsFromSheer(event) &&
      event.status === IN_PROGRESS,
  );

export const sheerRepliedOver72HoursAgo = (events: Event[]) =>
  events.filter(
    event => caseIsFromSheer(event) && caseIsFromOver72HoursAgo(event),
  );
